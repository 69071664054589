import { Scene } from "phaser";
import store from "@/store";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossRound = data.bossRound;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  selectedOption = null;

  create() {
    this.bgSound = this.sound.add("bgm");
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
    this.bgSound.loop = true;
    this.bgSound.play();
    this.scene.launch("PlayScene", {
      avatar: this.avatar,
      questions: this.questions,
      env: this.env,
      noOfRounds: this.noOfRounds,
      round: 1,
      friend: this.friend,
      score: 0,
      difficulty: this.difficulty,
      countCorrect: 0,
      avatarX: 220,
    });
  }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}
